
import {computed, onMounted, onUpdated, ref} from "vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";
import UserOfficesSelect from "@/components/catalogs-select/UserOfficesSelect.vue";
import CustomerEntityOfficeSelect from "@/components/catalogs-select/CustomerEntityOfficeSelect.vue";
import { useRouter } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import moment from "moment";
import { useStore } from "vuex";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
}

export default {
  name: "ManageQuotations",
  components: {
    TableBasicSelect,
    UserOfficesSelect,
    CustomerEntityOfficeSelect,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const search = ref("");
    const dataList = ref([]);
    const filtered = ref([]);
    const code = ref("");
    const creation_date = ref("");
    const expire_date = ref("");
    const office_id = ref([] as any);
    const entity_id = ref([] as any);
    const tableButtons = ref([]);
    const originalData = ref([]);
    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };
    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: user.value.user_preferences.items_per_page,
      total: 0,
    });

    const header = ref([
      { column: t("icode"), key: "code" },
      { column: t("icreationdate"), key: "created_date" },
      { column: t("expiration_date"), key: "expire_date" },
      { column: t("iprice"), key: "price" },
      { column: t("ticustomer"), key: "client_id" },
    ]);

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };

    const canCreate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
            permissions.value.filter((x) => x.name === "quote: create").length >
            0
        );
      }
      return false;
    });

    const canShow = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
            permissions.value.filter((x) => x.name === "quote: show").length >
            0
        );
      }
      return false;
    });

    const canUpdate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
            permissions.value.filter((x) => x.name === "quote: update").length >
            0
        );
      }
      return false;
    });

    const canDelete = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
            permissions.value.filter((x) => x.name === "quote: delete").length >
            0
        );
      }
      return false;
    });

    const createElement = () => {
      router.push({ name: "quotations" });
    };

    const formatDate = (value) => {
      return moment(value).format("DD/MM/YYYY");
    };

    const createTableItems = (data) => {
      const result = [] as any;
      data.map(function (element) {
        result.push({
          id: element.id,
          code: element.code,
          created_date: element.created_date,
          expire_date: element.expire_date,
          price: element.price,
          client_id: element.entity.name,
        });
      });
      return result;
    };

    const updateData = (data) => {
      originalData.value = data;
      dataList.value = createTableItems(data);
      filtered.value = dataList.value;
      pagination.value.total = data.length;
      tableButtons.value = [
        {
          name: "Editar" as never,
          type: "EDIT" as never,
          color: "primary" as never,
        } as never,
        {
          name: "Duplicar" as never,
          type: "DUPLICATE-2" as never,
          color: "primary" as never,
        } as never,
      ];
    };

    const getItems = async () => {
      store.commit("setLoadingStatus", true);
      ApiService.post("/api/quote/list", {}).then(({ data }) => {
        originalData.value = data;
        dataList.value = createTableItems(data);
        pagination.value.total = data;
        filtered.value = createTableItems(data);
        updateData(data);
        store.commit("setLoadingStatus", false);
      });
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("", [
        { route: "/manage_quotations", label: "manage_quotations" },
      ]);
      getItems();
    });

    onUpdated(() => {
      console.clear();
    });

    const currentPageChange = (val) => {
      pagination.value.page = val;
      serverSearch();
    };

    const setItemsPerPage = (event) => {
      pagination.value.rowsPerPage =
        typeof event === "object" ? parseInt(event.target.value) : event;
      serverSearch();
    };

    const serverSearch = () => {
      store.commit("setLoadingStatus", true);
      ApiService.query(`/api/maritimefiles`, {
        params: {
          page: pagination.value.page,
          per_page: pagination.value.rowsPerPage,
          code: code.value,
        },
      }).then(({ data }) => {
        originalData.value = data;
        dataList.value = createTableItems(data);
        pagination.value.total = data.total;
        filtered.value = createTableItems(data);
        store.commit("setLoadingStatus", false);
      });
    };

    const onSearch = () => {
      filtered.value = dataList.value.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
    };

    const actionsButton = (param1, param2, param3) => {
      if (param2 === "EDIT") {
        router.push({ name: "manage_quotations_edit", params: { id: param1 } });
      }
    };

    return {
      canCreate,
      canShow,
      canUpdate,
      canDelete,
      filtered,
      dataList,
      search,
      code,
      creation_date,
      expire_date,
      office_id,
      entity_id,
      header,
      tableButtons,
      pagination,
      formatDate,
      onSearch,
      serverSearch,
      createElement,
      actionsButton,
      currentPageChange,
      setItemsPerPage,
      user,
    };
  },
};
